import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { PageLayout } from '../layouts'
import {
  Section,
  AitoShowcases,
  ThreeColumnsList,
  PartnerCarousel,
  SimpleCallToActionBanner
} from '../components'
import * as styles from '../styles/pages/partner'
import variables from '../styles/variables'
import system10 from '../../assets/system10.svg'

const propTypes = {
  data: PropTypes.object.isRequired
}

export default function PartnerPage({ data }) {
  const { allYaml } = data
  const { edges } = allYaml
  const { node } = _.first(edges)

  const aitoBenefitsIcons = [
    (
      <styles.IconWrapper color="green">
        <styles.WinnerIcon />
      </styles.IconWrapper>
    ),
    (
      <styles.IconWrapper color="pink">
        <styles.PiggyBankIcon />
      </styles.IconWrapper>
    ),
    (
      <styles.IconWrapper color="blue">
        <styles.HiddenMagicIcon />
      </styles.IconWrapper>
    )
  ]

  const firstChild = (
    <styles.IntroContainer>
      <styles.TextWrapper>
        <styles.CenteredHeader>
          {node.header}
        </styles.CenteredHeader>
        <styles.CenteredHeaderInfo>
          {node.headerInfo}
        </styles.CenteredHeaderInfo>
        <styles.ButtonContainer>
          <a href={node.callToActionButtonUrl} target="_blank" rel="noopener noreferrer">
            <styles.HeaderButton
              type="outline"
              buttonColor={variables.colors.silverTree.medium}
              textColor={variables.colors.silverTree.medium}
              size="small"
            >
              {node.callToActionButtonText}
            </styles.HeaderButton>
          </a>
        </styles.ButtonContainer>
      </styles.TextWrapper>
      <styles.IntroSectionSideSquare src={system10} alt="" />
      <styles.IntroSectionBottomSquare src={system10} alt="" />
    </styles.IntroContainer>
  )

  return (
    <PageLayout
      firstChild={firstChild}
      navbarProps={{ background: variables.colors.oracle.dark }}
      description={node.metaDescription}
      sharingTitle={node.title}
    >
      <Helmet>
        <title>{node.title}</title>
      </Helmet>
      <Section>
        <styles.Container>

          <ThreeColumnsList
            header={node.whyPartnerHeader}
            items={node.whyPartner}
            icons={aitoBenefitsIcons}
          />

          <styles.PlusPatternContainer>
            <ThreeColumnsList
              header={node.partnerBenefitsHeader}
              items={node.partnerBenefits}
            />
          </styles.PlusPatternContainer>

          <AitoShowcases showcases={node.showcases} header={node.showcasesHeader} />

          <SimpleCallToActionBanner
            header={node.callToActionHeader}
            buttonUrl={node.callToActionButtonUrl}
            buttonText={node.callToActionButtonText}
            target="_blank"
          />

          <styles.LogoContainer>
            <styles.SectionContainer>
              <PartnerCarousel />
            </styles.SectionContainer>
          </styles.LogoContainer>

        </styles.Container>
      </Section>
    </PageLayout>
  )
}

PartnerPage.propTypes = propTypes

export const contentQuery = graphql`
query GetPartnerContent {

  allYaml(filter: {file: {eq: "partner"}}) {
    edges {
      node {
        title
        header
        headerInfo
        metaDescription

        whyPartnerHeader
        whyPartner {
          header
          text
        }

        partnerBenefitsHeader
        partnerBenefits {
          header
          text
        }

        showcasesHeader
        showcases {
          header
          text
          url
          image
        }

        callToActionHeader
        callToActionButtonText
        callToActionButtonUrl

      }
    }
  }
}
`
