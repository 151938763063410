import styled from 'styled-components'
import { ChevronRight } from '@styled-icons/octicons'
import variables from '../variables'
import { TextWrapper as BaseTextWrapper } from '../base'
import { headerStyles } from '../global'
import { Heading, Button as BaseButton } from '../../components'
import { ReactComponent as WinnerPic } from '../../../assets/winner.svg'
import { ReactComponent as PiggyBankpic } from '../../../assets/piggy-bank.svg'
import { ReactComponent as HiddenMagicPic } from '../../../assets/hidden-magic-black.svg'
import plusPattern from '../../../assets/plus-pattern.svg'

export const Container = styled.div`
  width: 100%;
`
export const IntroContainer = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: ${variables.colors.oracle.dark};
`

export const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.color === 'green' && `
    background: rgba(0, 184, 86, 0.22);
  `}

  ${props => props.color === 'pink' && `
    background: rgba(255, 169, 211, 0.38);
  `}

  ${props => props.color === 'blue' && `
    background: rgba(66, 192, 247, 0.28);
  `}
`

export const PlusPatternContainer = styled.div`
  width: 100%;
  background-image: url(${plusPattern});
`

export const LogoContainer = styled.div`
  width: 100%;
  background-color: #f4f4f4;
`

export const SectionContainer = styled(BaseTextWrapper)`
  padding: 20px 0 0 0;
  @media ${variables.media.sm} {
    padding: 60px 40px;
  }
  @media ${variables.media.md} {
    padding: 0 30px;
  }
`

export const TextWrapper = styled(BaseTextWrapper)`
  display: flex !important;
  flex-direction: column;
  align-items: center;
`

export const CenteredHeader = styled(Heading)`
  ${headerStyles.headerSmall}
  color: white;
  text-align: center;
  font-weight: 400;
  font-family: ${variables.fonts.body};
  margin-bottom: 1rem;
  animation: slideUp 0.5s ease-in-out;
`

export const CenteredHeaderInfo = styled.p`
  text-align: center;
  max-width: 400px;
  color: ${variables.colors.oracle.light};
  margin-bottom: 1.5rem;
  margin-top: 0;
  animation: slideUp 0.5s ease-in-out 0.1s;
  animation-fill-mode: forwards;
  opacity: 0;
`

export const IntroSectionSideSquare = styled.img`
  display: none;
  visibility: hidden;

  @media ${variables.media.sm} {
    display: block;
    visibility: visible;
    position: absolute;
    bottom: 0px;
    right: 0px;
    transform: translate(50%, 50%) rotate(45deg);
    z-index: 1;
    height: 200px;
  }
`

export const IntroSectionBottomSquare = styled.img`
  display: none;
  visibility: hidden;

  @media ${variables.media.sm} {
    display: block;
    visibility: visible;
    position: absolute;
    bottom: -75px;
    left: 10%;
    transform: translate(50%, 50%) rotate(45deg);
    z-index: 1;
    height: 200px;
  }
`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const HeaderButton = styled(BaseButton)`
  transition: 150ms ease-in;
  margin: 0 10px 5px 0;
`

export const Section = styled(BaseTextWrapper)`
  margin-top: 50px;
  margin-bottom: 100px;
`

export const Centerer = styled.div`
  display: flex;
  justify-content: center;
`

export const MainCTA = styled.div`
  padding: 0 2rem;
  margin: 100px 0;
  @media ${variables.media.sm} {
    display: flex;
    justify-content: row;
    justify-content: space-between;
  }
`

export const MainCTAHeader = styled(Heading)`
  ${headerStyles.headerSmall};
  color: ${variables.colors.jade.medium};
`

export const MainCTAText = styled.p`
  color: ${variables.colors.oracle.dark};
`

export const LearnMore = styled.div`
  height: 20px;
`

export const Chevron = styled(ChevronRight)`
  width: 12px;
  height: 12px;
`

export const WinnerIcon = styled(WinnerPic)`
  width: 42px;
  height: 80px;
`

export const PiggyBankIcon = styled(PiggyBankpic)`
  width: 48px;
  height: 80px;
`

export const HiddenMagicIcon = styled(HiddenMagicPic)`
  width: 70px;
  height: 70px;
`
